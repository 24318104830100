/*------------- #TABS --------------*/


.tabs {
  overflow: hidden;

  ul {
	list-style: none;
	padding-left: 0;
	margin-bottom: 40px;
	margin-top: 0;

	li {
	  display: inline-block;
	  line-height: 1;
	  margin-bottom: 0;

	  a {
		line-height: 1;
	  }

	  &.lessons__item {
		margin-bottom: 30px;
		line-height: inherit;
	  }
	}
  }
}

.tab-title {
  transition: all .3s ease;

  & > * {
	transition: inherit;
  }
}

.tab-content > .tab-pane {
  display: none;

  &.active {
	display: block;
  }

  &.fade {
	opacity: 1;
  }
}

.tabs--style1 {
  padding: 80px;
  background-color: $bg-accordion;
  border-radius: 20px;

  .tab-content-title {
	margin-bottom: 50px;

	& > span {
	  color: $icon-color;
	}
  }

  .action-link {
	margin-top: 50px;
  }

  ul {
	display: flex;
	margin-bottom: 60px;

	li {
	  & + li {
		margin-left: 20px;
	  }
	}

	a {
	  padding: 13px 28px;
	  line-height: 1;
	  position: relative;
	  border-radius: 50px;
	  display: inline-block;
	  background-color: $bg-accordion-grey;

	  .tab-title {
		font-size: 18px;
		margin: 0;
	  }

	  &:hover {
		background-color: $primary-color;
	  }

	  &:active {
		background-color: $primary-color;
	  }
	}

	.active {
	  a {
		background-color: $primary-color;
	  }
	}
  }
}

.tabs--style2 {
  padding: 80px;
  background-color: $bg-accordion;
  border-radius: 20px;


  .tab-content {
	.btn {
	  margin-top: 20px;
	}
  }

  .tab-content-title {
	& > span {
	  color: $white-color;
	}
  }

  ul {
	display: flex;
	text-align: center;

	li {
	  width: 100%;

	  a {
		padding: 0 0 30px 0;
		position: relative;

		&:after {
		  content: '';
		  display: block;
		  position: absolute;
		  width: 100%;
		  height: 4px;
		  background-color: $hr-color;
		  border-radius: 3px;
		  transition: all .3s ease;
		  bottom: 0;
		  left: 50%;
		  transform: translate(-50%, 0);
		}

		.tab-title {
		  font-size: 20px;

		  & > span {
			font-size: 14px;
			color: $icon-color;
		  }
		}

		&:hover {
		  .tab-title {
			color: $primary-color;

			& > span {
			  color: $white-color;
			}
		  }

		  &:after {
			background-color: $primary-color;
		  }
		}

		&:active {
		  .tab-title {
			color: $primary-color;

			& > span {
			  color: $white-color;
			}
		  }

		  &:after {
			background-color: $primary-color;
		  }
		}
	  }

	  &.active {
		a {

		  .tab-title {
			color: $primary-color;

			& > span {
			  color: $white-color;
			}
		  }

		  &:after {
			background-color: $primary-color;
		  }
		}
	  }
	}
  }
}

.tabs--style3 {
  padding: 0;
  margin-bottom: 0;
  background-color: transparent;
  overflow: visible;

  .currency-details--inline {
	margin-top: 60px;
  }

  .tab-content {
	padding: 80px;
	border-radius: 0 20px 20px 20px;
	background-color: $bg-accordion;
  }

  .tab-content-title {
	color: $primary-color;
  }

  ul {
	margin-bottom: 0;

	li {

	  & + li {
		margin-left: -5px;
	  }

	  a {
		padding: 30px 40px;
		background-color: #101114;
		position: relative;

		.tab-title {
		  font-size: 20px;

		  & > span {
			font-size: 14px;
			color: $icon-color;
		  }
		}

		&:after {
		  content: '';
		  display: block;
		  position: absolute;
		  width: 70%;
		  height: 2px;
		  background-color: $primary-color;
		  transition: all .3s ease;
		  top: 0;
		  left: 50%;
		  transform: translate(-50%, 0);
		  opacity: 0;
		}

		&:hover {
		  background-color: $bg-accordion;

		  .tab-title {
			color: $primary-color;

			& > span {
			  color: $white-color;
			}
		  }
		}
	  }

	  &.active {
		a {
		  background-color: $bg-accordion;

		  .tab-title {
			color: $primary-color;

			& > span {
			  color: $white-color;
			}
		  }

		  &:after {
			opacity: 1;
		  }
		}
	  }

	  &:last-child {
		a {
		  border-radius: 0 20px 0 0;
		}
	  }

	  &:first-child {
		a {
		  border-radius: 20px 0 0 0;
		}
	  }
	}
  }
}

.tabs--style4 {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  overflow: visible;
  padding: 60px 120px;

  ul {
	margin-bottom: 60px;

	li {

	  a {
		position: relative;
		border-bottom: 2px dotted $primary-color;
		padding: 5px 0;

		.tab-title {
		  color: $primary-color;
		  margin: 0;
		}

		&:hover {
		  border-bottom-color: transparent;

		  .tab-title {
			color: $link-color;
		  }
		}

		&:active {
		  border-bottom-color: transparent;

		  .tab-title {
			color: $link-color;
		  }
		}
	  }

	  &.active {
		z-index: 10;

		a {
		  border-bottom-color: transparent;

		  .tab-title {
			color: $link-color;
		  }
		}
	  }

	  & + li {
		margin-left: 40px;
	  }
	}
  }

  .action-link {
	margin-top: 50px;
  }

  .tab-content {
	.btn {
	  margin-top: 50px;
	}
  }
}

.tabs--style5 {
  background-color: transparent;
  padding: 60px 0;
  border-radius: 0;

  & > ul {
	display: flex;
	align-items: center;
	margin-bottom: 60px;

	li {
	  width: 100%;

	  a {
		position: relative;
		padding-bottom: 40px;

		&:after {
		  content: '';
		  display: block;
		  position: absolute;
		  width: 100%;
		  height: 4px;
		  background-color: $hr-color;
		  border-radius: 3px;
		  transition: all .3s ease;
		  bottom: 0;
		  left: 50%;
		  transform: translate(-50%, 0);
		}

		.tab-title {

		  & > span {
			display: block;
			font-size: 14px;
			color: $icon-color;
		  }
		}

		&:hover {

		  &:after {
			background-color: $primary-color;
		  }

		  .tab-title {
			color: $primary-color;
		  }
		}

		&:active {

		  &:after {
			background-color: $primary-color;
		  }

		  .tab-title {
			color: $primary-color;
		  }
		}
	  }

	  &.active {

		a {

		  &:after {
			background-color: $primary-color;
		  }

		  .tab-title {
			color: $primary-color;
		  }
		}
	  }
	}
  }
}

.tabs--style6 {
  display: flex;
  overflow: visible;

  & > ul {
	min-width: 20%;
	margin-bottom: 0;

	li {
	  display: block;

	  a {
		padding: 30px 40px;
		position: relative;
		border-bottom: 1px solid $table-border-color;
		border-left: 2px solid transparent;
		background-color: #101114;

		.tab-title {
		  font-size: 20px;

		  & > span {
			font-size: 14px;
			color: $icon-color;
		  }
		}

		&:hover {
		  background-color: $bg-accordion;
		  border-left-color: $primary-color;

		  .tab-title {
			color: $primary-color;

			& > span {
			  color: $white-color;
			}
		  }
		}

		&:active {
		  background-color: $bg-accordion;
		  border-left-color: $primary-color;

		  .tab-title {
			color: $primary-color;

			& > span {
			  color: $white-color;
			}
		  }
		}
	  }

	  &.active {

		a {
		  background-color: $bg-accordion;
		  border-left-color: $primary-color;

		  .tab-title {
			color: $primary-color;

			& > span {
			  color: $white-color;
			}
		  }
		}
	  }

	  &:first-child {
		a {
		  border-radius: 20px 0 0 0;
		}
	  }

	  &:last-child {
		a {
		  border-radius: 0 0 0 20px;
		}
	  }
	}
  }

  .tab-content {
	padding: 120px;
	background-color: $bg-accordion;
	border-radius: 0 20px 20px 20px;
  }
}

.tabs--style-scaled {

  .tab-content {
	padding: 120px 80px;
	background-color: $bg-accordion;
	border-radius: 20px;
  }

  & > ul {
	text-align: center;
	margin-bottom: 80px;

	li {

	  &:hover,
	  &.active {

		a {

		  .tab-title {
			color: $primary-color;
			top: 100%;
			transform: translate(-50%, 0);

			span {
			  color: $primary-color;
			}
		  }

		  .tab-thumb {
			transform: scale(.8);
			border-color: $primary-color;

			.woox-icon {
			  fill: $primary-color;
			}
		  }
		}
	  }

	  a {
		text-align: center;
		position: relative;

		.tab-title {
		  font-size: 20px;
		  color: $white-color;
		  position: absolute;
		  top: 50%;
		  left: 50%;
		  transform: translate(-50%, -50%);
		  transition: all .3s ease;

		  span {
			display: block;
			color: $icon-color;
		  }
		}

		.tab-thumb {
		  width: 160px;
		  height: 160px;
		  max-width: 160px;
		  max-height: 160px;
		  border: 10px double $border-color;
		  border-radius: 100%;
		  position: relative;
		  transition: all .3s ease;

		  .woox-icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			width: 100px;
			height: 100px;
			max-width: 100px;
			max-height: 100px;
			fill: $border-color;
		  }
		}
	  }

	  & + li {
		margin-left: 40px;
	  }
	}
  }
}

.value-growth {
  margin: 0;
  display: inline-block;

  .growth {
	font-size: 20px;
	color: #3cb44e;
	display: inline-block;
  }
}

.tab-content-title {
  display: inline-flex;
  margin-bottom: 60px;

  & > span {
	color: $primary-color;
	margin-left: 10px;
  }

  & + .value-growth {
	margin-left: 50px;
  }

  .woox-icon {
	width: 30px;
	height: 30px;
	margin-left: 30px;
	fill: $border-color;
  }
}

.currency-details {
  border-radius: 20px;
  padding: 60px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .currency-details-item {

	& + .currency-details-item {
	  margin-left: 55px;
	}

	.title {
	  font-size: 18px;
	  color: $icon-color;
	  margin-top: 0;
	}

	.value {
	  margin-bottom: 0;
	}

	&:last-child {
	  padding-bottom: 0;
	  margin-bottom: 0;
	  border-bottom: none;
	}
  }
}

.currency-details--inline {
  padding: 40px 0;
  border-radius: 0;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  border-top: 2px solid $table-border-color;

  .currency-details-item {
	padding-bottom: 0;
	margin-bottom: 0;
	border-bottom: none;

	.title {
	  font-size: 16px;
	}

	.value {
	  font-size: 16px;
	}
  }
}

.action-link {
  display: block;
  font-size: 18px;
  font-weight: $h-font-weight;
  color: rgba($primary-color, .7);

  &:hover {
	color: rgba($primary-color, 1);

	.woox-icon {
	  fill: rgba($primary-color, 1);
	}
  }

  .woox-icon {
	fill: rgba($primary-color, .7);
	width: 12px;
	height: 12px;
	margin-left: 20px;
  }
}

/*================= Responsive Mode ============*/

@media (max-width: 1400px) {
  .tabs--style-scaled > ul li a .tab-thumb {
	width: 90px;
	height: 90px;
	max-width: 90px;
	max-height: 90px;
	border-width: 5px;
  }

  .tabs--style-scaled > ul li a .tab-thumb .woox-icon {
	width: 50px;
	height: 50px;
	max-width: 50px;
	max-height: 50px;
  }
}

@media (max-width: 1300px) {

  .tabs--style1 {
	padding: 40px;
  }

  .tabs--style4 ul {
	margin-bottom: 30px;
  }

  .tabs--style4 {
	padding: 40px;
  }

  .tabs--style1 ul {
	margin-bottom: 30px;
  }

  .tabs--style1 .tab-content-title {
	margin-bottom: 30px;
  }

  .tabs--style6 .tab-content {
	padding: 40px;
  }

  .tabs--style6 > ul li a .tab-title {
	font-size: 16px;
  }

  .tabs--style6 > ul li a {
	padding: 15px 30px;
  }

  .tabs--style3 .tab-content {
	padding: 40px;
  }

  .tabs--style3 ul li a .tab-title {
	font-size: 16px;
  }

  .tabs--style3 ul li a {
	padding: 15px 20px;
  }

  .tab-content-title {
	margin-bottom: 20px;
  }

  .tabs--style3 .currency-details--inline {
	margin-top: 20px;
  }

  .tabs--style2 ul li a .tab-title {
	font-size: 16px;
  }

  .tabs--style2 ul li a {
	padding: 0 0 10px;
  }

  .tabs--style2 {
	padding: 40px;
  }

  .currency-details .currency-details-item + .currency-details-item {
	margin-left: 20px;
  }

  .currency-details--inline .currency-details-item .title,
  .currency-details--inline .currency-details-item .value {
	font-size: 14px;
  }

  .currency-details--inline {
	padding: 20px 0;
  }
}

@media (max-width: 1200px) {
  .currency-details {
	flex-wrap: wrap;
  }

  .currency-details .currency-details-item {
	margin-bottom: 20px;
  }

  .currency-details .currency-details-item + .currency-details-item {
	margin-left: 0;
  }

  .tabs--style-scaled .tab-content {
	padding: 60px 40px;
  }
}

@media (max-width: 991px) {
  .tabs--style6 {
	flex-direction: column;
  }

  .tabs--style6 > ul {
	min-width: unset;
  }

  .tabs--style6 .tab-content {
	border-radius: 0 0 20px 20px;
  }

  .tabs--style6 > ul li:last-child a {
	border-radius: 0;
  }

  .tabs--style6 > ul li:first-child a {
	border-radius: 20px 20px 0 0;
  }

  .tabs--style6 > ul li a {
	padding: 10px 30px;
  }

  .tabs--style1 ul a .tab-title {
	font-size: 14px;
  }

  .tabs--style1 ul {
	flex-direction: column;
  }

  .tabs--style1 ul li a {
	text-align: center;
  }

  .tabs--style1 ul li {

	& + li {
	  margin-left: 0;
	  margin-top: 20px;
	}
  }

  .tabs--style1 .action-link {
	margin-top: 20px;
	font-size: 14px;
  }
}

@media (max-width: 768px) {
  .tabs--style-scaled .tab-content {
	padding: 20px;
  }

  .currency-details .currency-details-item .title {
	font-size: 16px;
  }

  .value-growth {
	display: block;
	margin-bottom: 20px;
  }

  .tab-content-title + .value-growth {
	margin-left: 0;
  }

  .tabs--style-scaled > ul li + li,
  .tabs--style4 ul li + li {
	margin-left: 20px;
  }

  .tabs--style5 > ul li a .tab-title > span {
	font-size: 11px;
  }
}

@media (max-width: 600px) {
  .tabs--style-scaled > ul li a .tab-thumb {
	width: 60px;
	height: 60px;
	max-width: 60px;
	max-height: 60px;
  }

  .tabs--style-scaled > ul li a .tab-thumb .woox-icon {
	width: 30px;
	height: 30px;
	max-width: 30px;
	max-height: 30px;
  }

  .tabs--style-scaled > ul li a .tab-title {
	display: none;
  }

  .tabs--style-scaled > ul {
	margin-bottom: 20px;
  }

  .tabs--style-scaled > ul li + li,
  .tabs--style4 ul li + li {
	margin-left: 10px;
  }
}

@media (max-width: 540px) {
  .tabs--style3 ul li {
	display: block;
	text-align: center;
  }

  .tabs--style3 ul li a {
	padding: 5px 20px;
  }

  .tabs--style3 ul li + li {
	margin-left: 0;
	margin-top: 20px;
  }

  .tabs--style3 ul li a {
	border-radius: 50px;
  }

  .tabs--style3 ul li:first-child a {
	border-radius: 50px;
  }

  .tabs--style3 ul li:last-child a {
	border-radius: 50px;
  }

  .tabs--style3 .tab-content {
	margin-top: 20px;
	border-radius: 20px;
  }

  .tabs--style3 .content-align-justify {
	flex-direction: column;
	align-items: flex-start;

	& > * {
	  margin-bottom: 20px;

	  &:last-child {
		margin-bottom: 0;
	  }
	}
  }

  .currency-details--inline {
	flex-direction: column;
	align-items: flex-start;

	.currency-details-item + .currency-details-item {
	  margin-left: 0;
	  margin-top: 20px;
	}
  }

  .currency-details .currency-details-item + .currency-details-item {
	margin-left: 0;
  }

  .tabs--style2 ul {
	flex-direction: column;

	li {
	  margin-bottom: 20px;

	  &:last-child {
		margin-bottom: 0;
	  }
	}
  }

  .tabs--style4 ul li a {
	font-size: 16px;
  }

  .tabs--style5 > ul li a {
	padding-bottom: 20px;
  }
}

@media (max-width: 460px) {
  .tabs--style-scaled > ul li a .tab-thumb {
	width: 40px;
	height: 40px;
	max-width: 40px;
	max-height: 40px;
  }

  .tabs--style5 > ul {
	flex-wrap: wrap;

	li {
	  margin-bottom: 20px;

	  &:last-child {
		margin-bottom: 0;
	  }
	}
  }
}
